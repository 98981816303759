.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.dark-mode .icon-bar::after {
  background-color: white; /* Couleur en mode sombre */
}

.dark-mode .icon-bar a {
  color: white;
}

.dark-mode .shadow {
  box-shadow: 0 2px 4px rgba(65, 64, 64, 0.1) !important;
}

.dark-mode .navigation {
  background-color: #121212;
}

.dark-mode .navigation-logo {
  color: #ffffff;
}

.dark-mode .footer {
  background-color: #121212;
  box-shadow: 0 -2px 4px rgba(65, 64, 64, 0.1);
}

.dark-mode #typing-effect {
  border-right: 0.1em solid white; /* Le curseur simulé en mode sombre */
}

.dark-mode .puce {
  color: #ffffff;
}

.dark-mode .github-bis {
  color: #ffffff;
}

.dark-mode .text-pre {
  color: #ffffff;
}

.dark-mode .title-text-pre {
  color: #b3b3b3;
}
