@font-face {
  font-family: "font-1";
  src: url(../assets/fonts/EuropaRegular.ttf);
}

@font-face {
  font-family: "font-2";
  src: url(../assets/fonts/FiraCode-Regular.ttf);
}

@font-face {
  font-family: "font-3";
  src: url(../assets/fonts/europa-light-webfont.ttf);
}

$font-1: "font-1", sans-serif;
$font-2: "font-2", sans-serif;
$font-3: "font-3", sans-serif;

$black: #000000;
$gray: #b3b3b3;
$white: #ffffff;

@mixin verticalCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: $white;
  font-family: $font-1;
  position: relative;
}
li {
  list-style-type: none;
}

html {
  scroll-behavior: smooth;
  max-width: 100vw;
  overflow-x: hidden;
}
