.icon-bar {
  position: fixed;
  bottom: 15px;
  right: 20px;
  display: flex; /* Utilisation de flexbox */
  flex-direction: column; /* Orientation des éléments en colonne */
  align-items: center; /* Centrage horizontal des éléments */
}

.icon-bar a {
  display: flex;
  justify-content: center; /* Centre horizontalement le contenu dans le conteneur */
  align-items: center;
  text-align: center;
  padding: 0px; /* Réduit le padding pour resserrer les icônes */
  transition: all 0.3s ease;
  color: black; /* Couleur des icônes en noir */
  font-size: 22px;
  background: transparent; /* Fond transparent */
  height: 40px;
}

.icon-bar a:hover {
  color: #f68886; /* Couleur des icônes en gris clair au survol */
  background-color: transparent; /* Maintenir le fond transparent au survol */
}

/* Modification pour la ligne verticale centrée */
.icon-bar::after {
  content: "";
  height: 120px; /* Hauteur de la ligne */
  width: 2px; /* Largeur de la ligne */
  background-color: black; /* Couleur de la ligne */
  margin-top: 15px;
}

.content {
  margin-left: 100px; /* Ajustez selon la présence de la barre d'icônes */
  font-size: 30px;
}
