.shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}
.navigation {
  font-family: $font-1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 70px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: box-shadow 0.5s ease-in-out;
  background-color: $white;
  color: #000;

  .navigation-logo {
    font-weight: 700;
    font-size: 22px;
    font-style: normal;
    line-height: 1.2em;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    transition: all 0.3s ease;
  }

  .navigation-links {
    display: flex;
    align-items: center;

    .nav-link {
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      letter-spacing: 0.04em;
      line-height: 1em;
      text-decoration: none;
      color: #b3b3b3;
      margin-left: 22px;
    }
  }
}

.navbarBurger {
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .navigation.isMobile .navigation-logo {
    margin: 0 auto;
  }

  .navigation-links {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    position: fixed;
    right: -100vw;
    bottom: 0;
    width: 0;
    height: 100vh;
    padding: 2rem;
    visibility: hidden;
    background-color: #383e42;
    color: white;
    transition: all 0.8s ease-out;
  }

  .navigation.show-nav .navigation-links {
    width: 100vw;
    right: 0;
    visibility: visible;
  }

  .navigation-links .nav-link {
    display: block;
    padding: 1.5rem;
    font-size: 5vw !important;
  }

  .navbarBurger {
    display: block;
    position: fixed;
    top: 1rem;
    right: 1rem;
  }

  .nav-link-divider {
    display: none !important;
  }

  .burgerBar,
  .burgerBar::before,
  .burgerBar::after {
    display: block;
    width: 30px;
    height: 2px;
    position: relative;
    border-radius: 3px;
    background: #9b9b9b;
    transition: all 0.5s ease-in-out;
  }

  .burgerBar::before,
  .burgerBar::after {
    content: "";
    position: absolute;
    left: 0;
  }

  .burgerBar::before {
    transform: translateY(-8px);
  }

  .burgerBar::after {
    transform: translateY(8px);
  }

  .show-nav .burgerBar::before {
    transform: rotate(45deg);
  }

  .show-nav .burgerBar::after {
    transform: rotate(-45deg);
  }

  .show-nav .burgerBar {
    background: transparent;
  }
  .drapeau {
    display: none;
  }
}

.scrolled {
  background-color: #222;
  color: white;
  min-height: 25px;
}

.theme-icon {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
}

.icon-sun,
.icon-moon {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease;
  opacity: 1;
}

.icon-hidden {
  opacity: 0;
}

.drapeau {
  width: 18px;
  height: auto;
}

.nav-linkbis {
  margin-left: 15px;
  font-size: 14px;
  padding-bottom: 2px;
}
