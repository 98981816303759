#contact {
  padding-top: 80px;
  padding-bottom: 40px;
  padding-left: 100px;
  padding-right: 100px;
  width: 75%;
}

.contact-title {
  font-family: $font-1;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
}

.contact-title-bis {
  font-family: $font-1;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
}

.contact-pre-title {
  font-family: $font-1;
  font-weight: 100;
  font-size: 15px;
  color: #f68886;
}

.contact-text {
  padding-top: 20px;
  padding-bottom: 20px;
}

.contact-button {
  font-family: $font-2;
  --button_radius: 0%;
  --button_color: #fff;
  --button_outline_color: #000000;
  --shadow-color: rgba(0, 0, 0, 0.2);
  --highlight-color: rgba(255, 255, 255, 0.2);
  font-size: 17px;
  font-weight: 100;
  letter-spacing: 0.2px;
  border: none;
  border-radius: var(--button_radius);
  background: linear-gradient(
    to bottom right,
    var(--highlight-color),
    var(--shadow-color)
  );
  box-shadow: 6px 6px 16px var(--shadow-color),
    -6px -6px 16px var(--highlight-color);
  cursor: pointer;

  .button_top {
    display: block;
    box-sizing: border-box;
    border: 2px solid var(--button_outline_color);
    border-radius: var(--button_radius);
    padding: 0.75em 1.5em;
    background: var(--button_color);
    color: var(--button_outline_color);
    transform: translateY(-0.2em);
    transition: transform 0.1s ease;
  }

  &:hover .button_top {
    transform: translateY(-0.33em);
  }

  &:active .button_top {
    transform: translateY(0);
  }
}

@media screen and (max-width: 767px) {
  #contact {
    padding-top: 100px;
    padding-left: 50px;
    padding-right: 50px;
    width: 100%;
  }

  .contact-button {
    font-size: 14px;
  }
}
