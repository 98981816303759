#home {
  min-height: 100vh;
}

.Highlight {
  color: #f68886;
}

.memoji {
  width: 250px;
  height: auto;
  object-fit: contain;
  padding-bottom: 35px;
  position: absolute; // Rendre l'image de memoji fixe si nécessaire
  right: 35px;
}

/* TYPING EFFECT */

@keyframes blink-caret-light {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

/* Animation pour le mode sombre */
@keyframes blink-caret-dark {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: white;
  }
}

#typing-effect {
  font-family: $font-1;
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.1em solid black; /* Le curseur simulé */
  animation: blink-caret 1s step-end infinite;
}

/* END TYPING EFFECT */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .memoji {
    display: none;
  }
}
